import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

const PostCardListSingle = ({ posts }) => {
  const PostCardAltSingle = loadable(() => import('../../postCards/postCardAltSingle'));
  return (
    <>
      <div className="row m-0">
        <div className="col-md-8 align-self-center p-3 mx-auto">
          <div className="row justify-content-center m-0">
            {posts.map(post => (
              <div key={post.title} className="col-md-12 px-0 py-4">
                <PostCardAltSingle data={post} key={post.title} />
              </div>
            ))}
          </div>
          <div />
        </div>
      </div>
    </>
  );
};

PostCardListSingle.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.string),
      cover: PropTypes.object,
      title: PropTypes.string,
      category: PropTypes.string,
      date: PropTypes.string,
      timeToRead: PropTypes.number,
      excerpt: PropTypes.string,
    }),
  ).isRequired,
};

export default React.memo(PostCardListSingle);
